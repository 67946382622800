import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"
import { Link, graphql } from "gatsby"
import { v4 } from "uuid"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  SectionPageTitle,
  SectionLead,
  BreadCrumb,
} from "../components/Section"
import { BulletIcon } from "../components/Icons"
import BreakpointUp from "../components/Media/BreakpointUp"
import FormCard from "../components/FormCard"

const GridRow = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  transition: position 0.5 ease;

  ${BreakpointUp.lg`
        margin:0 -30px;
        .sticky-wrapper {
            position: -webkit-sticky;  // required for Safari
            position: sticky;
            top: 100px;
            width: 100%;
        }
        .no-sticky {
            position: relative;
            top: 0;
        }
    `}
  @media(max-width: 989px) {
    .sticky-wrapper {
      position: relative !important;
      top: 0;
    }
  }
`
const GridLeftColumn = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`
        padding:0 30px;
        flex: 0 0 50%;
        max-width: 50%;
    `}
`
const GridRightColumn = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`
        padding:0 30px;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
`

const StepWrap = styled.div`
  .step-item {
    &:nth-child(even) {
      .step {
        flex-direction: row-reverse;
        .icon {
          ${BreakpointUp.lg`                        
                        margin-left: auto;
                    `}
        }
        .step-block {
          &:before {
            ${BreakpointUp.lg`                                                
                            left:0;
                            right:auto;
                        `}
          }
          &:after {
            ${BreakpointUp.lg`                                                
                            left: 7px;
                            right:auto;
                        `}
          }
        }
        .step-left {
          &:after {
            right: auto;
            left: 15px;
          }
        }
      }
    }
    &:first-child {
      .step {
        .icon {
          &:before {
            display: none;
          }
        }
      }
    }
    &:last-child {
      .step {
        .icon {
          &:after {
            display: none;
          }
        }
      }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`
const StepItem = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 82%;
    height: 2px;
    background-image: linear-gradient(
      to right,
      #cfcfcf 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 12px 4px;
    background-repeat: repeat-x;
    left: 50%;
    transform: translateX(-50%);
    max-width: 535px;
  }
`

const Step = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
  text-align: center;
  ${BreakpointUp.lg` 
    text-align:left;
    `}
`
const StepLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  &:after {
    ${BreakpointUp.lg`  
            content: '';
            position: absolute;
            top: 50%;
            right: 15px;
            width: 50%;
            height: 2px;
            background-image: linear-gradient(to right,#CFCFCF 50%,rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 12px 2px;
            background-repeat: repeat-x;
        `}
  }
  ${BreakpointUp.lg`
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
`
const StepRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;

  ${BreakpointUp.lg`     
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    `}
`

const StepBlock = styled.div`
  position: relative;

  &:before,
  &:after {
    display: none;
    ${BreakpointUp.lg`      
            display:block;                                                     
            content: '';
            position: absolute;
            top: 50%;
            transform:translateY(-50%);
            z-index: 1;
        `}
  }
  &:before {
    right: 0;
    width: 34px;
    height: 34px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #f6f6f6;
  }
  &:after {
    right: 7px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #cfcfcf;
  }
`

const StepIcon = styled.div`
  width: 108px;
  height: 108px;
  border-radius: 50%;
  border: 1px solid rgba(33, 31, 40, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  background: #f6f6f6;
  margin: 0 auto 15px auto;
  ${BreakpointUp.lg`      
        margin:0;
    `}
  &:after, &:before {
    display: none;
    ${BreakpointUp.lg` 
            display:block;     
            content: '';
            position: absolute;
            left: 50%;
            height: 40%;
            width: 3%;
            background-image: linear-gradient(to top,#CFCFCF 50%,rgba(255,255,255,0) 0%);
            background-position: bottom;
            background-size: 2px 12px;
            background-repeat: repeat-y;
        `}
  }
  &:after {
    top: 100%;
  }
  &:before {
    bottom: 100%;
  }
`

const StepInner = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const StepContent = styled.div`
  strong {
    color: #211f28;
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    margin: 0 0 10px;
    font-size: 18px;
    line-height: 24px;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }
    @media (min-width: 992px) {
      font-size: 26px;
      line-height: 30px;
    }
  }
  p {
    margin-bottom: 0;
    + p {
      margin-top: 15px;
    }
  }
`

function BuyerGuidePage({ data, intl }) {
  const pageData = data.contentfulGuidePage
  const [isSticky, setSticky] = useState(false)
  useEffect(() => {
    const startOffset = document
      .getElementById("sticky-top")
      .getBoundingClientRect().top
    const endOffsset = document
      .getElementById("sticky-end")
      .getBoundingClientRect().top
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (startOffset < currentScrollY && currentScrollY < endOffsset) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])
  return (
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <Link to="/">
            {intl.formatMessage({ id: "Home" })} <BulletIcon />
          </Link>
          <Link to="/">
            Buyer's Guide <BulletIcon />
          </Link>
          <span>{pageData.pageName}</span>
        </div>
      </BreadCrumb>
      <Section pt="250px" pb="150px" xpt="90px" xpb="60px" bgColor="#F6F6F6">
        <div className="container">
          <GridRow id="sticky-top">
            <GridLeftColumn>
              <SectionPageTitle textAlign="left">
                {pageData.pageName}
              </SectionPageTitle>
              <SectionLead textAlign="left">
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.heroDescription.childMarkdownRemark.html,
                  }}
                />
              </SectionLead>
              <StepWrap>
                {pageData.steps.map(step => (
                  <StepItem className="step-item" key={v4()}>
                    <Step className="step">
                      <StepLeft className="step-left">
                        <StepBlock className="step-block">
                          <StepIcon className="icon">
                            <StepInner>
                              <Img
                                fluid={step.featureImage.fluid}
                                alt={step.featureImage.description}
                              />
                            </StepInner>
                          </StepIcon>
                        </StepBlock>
                      </StepLeft>
                      <StepRight className="step-right">
                        <StepContent>
                          <strong>{step.feature}</strong>
                          <p>{step.description.description}</p>
                        </StepContent>
                      </StepRight>
                    </Step>
                  </StepItem>
                ))}
              </StepWrap>
            </GridLeftColumn>
            <GridRightColumn>
              <div className={isSticky ? "sticky-wrapper" : "no-sticky"}>
                <FormCard intl={intl} />
              </div>
            </GridRightColumn>
          </GridRow>
        </div>
      </Section>
      <div id="sticky-end"></div>
    </Layout>
  )
}

export default injectIntl(BuyerGuidePage)

export const query = graphql`
  query BuyerGuidePageQuery($slug: String, $locale: String) {
    contentfulGuidePage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      pageName
      metaTitle
      metaDescription
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      steps {
        feature
        description {
          description
        }
        featureImage {
          fluid(quality: 100) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          description
        }
      }
    }
  }
`
